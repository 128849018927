import React from "react";
import data from "../data/data.json";
import ListItem from "./ListItem";
import { Row, Col } from "react-bootstrap";

function List() {
  return (
    <Row>
      {data.listItems.map((item, i) => (
        <Col key={i} xs={12} sm={6} md={4} lg={4}>
          <ListItem item={item} />
        </Col>
      ))}
    </Row>
  );
}

export default List;
