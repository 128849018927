import React, { useState } from "react";
import { Container } from "react-bootstrap";
import Header from "./components/Header";
import Footer from "./components/Footer";
import List from "./components/List";
import "./style/style.scss";
import Hero from "./components/Hero";

function App() {
  const [darkTheme, setDarkTheme] = useState(true);
  const mainStyle =
    "xmas-list d-flex flex-column justify-between align-items-center";

  const onThemeChange = () => {
    setDarkTheme(!darkTheme);
  };

  return (
    <div className={`${mainStyle} ${darkTheme ? "dark" : "light"}`}>
      <Header onThemeChange={onThemeChange} />
      <Hero />
      <Container>
        <List />
      </Container>
      <Footer />
    </div>
  );
}

export default App;
