import React from "react";
import { Navbar } from "react-bootstrap";

function Header() {
  return (
    <Navbar className="w-100 d-flex justify-content-center align-items-center">
      <Navbar.Brand>Xmas List</Navbar.Brand>
    </Navbar>
  );
}

export default Header;
