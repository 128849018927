import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-light py-3 footer align">
      <Container>
        <Row>
          <Col>
            {/* <p className="text-center mb-0">zpixelz {currentYear}</p> */}
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
