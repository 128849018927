import React from "react";
import { Card, Button } from "react-bootstrap";

const ListItem = ({ item }) => {
  // If there's no link, use `#` as the default
  const link = item.link || "#";

  return (
    <Card>
      <Card.Body className="d-flex flex-column justify-content-between">
        <div className="d-flex flex-column justify-content-between">
          {item.title && (
            <Card.Title className="text-center p-3">{item.title}</Card.Title>
          )}
          {item.description && (
            <Card.Text className="px-3">{item.description}</Card.Text>
          )}
          {item.img && (
            <Card.Img
              className="card-img"
              variant="top"
              src={item.img}
              alt={item.title}
            />
          )}
        </div>
        <Button
          variant="primary"
          size="lg"
          className="w-100"
          href={link}
          target="_blank"
          rel="noopener noreferrer"
        >
          View
        </Button>
      </Card.Body>
    </Card>
  );
};

export default ListItem;
