import React from "react";
import Santee from "../img/santee.jpg";
import Divider from "./Divider";

const Hero = () => {
  return (
    <>
      <img className="hero-img" src={Santee} />
      <h1 className="main-heading text-center">Merry 666mas</h1>
      <Divider />
    </>
  );
};

export default Hero;
